import React, { useState } from 'react';
import styles from './AlertModal.module.scss';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

// import { BsInfoCircle, BsX } from 'react-icons/bs';
// import classNames from 'classnames';

const AlertModal = ({
  title,
  msg,
  btnText,
  icon,
  proceed,
  cancel,
  download,
  children,
  isButtonLoading = false,
}) => {
  const [shown, setShown] = useState(true);

  const onClick = () => {
    setShown((s) => !s);
    if (proceed) {
      proceed();
    }
  };

  return (
    <Modal isOpen={shown} onClose={cancel} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent className={styles.modalBody}>
        <ModalHeader>
          {title} {icon}
        </ModalHeader>
        {cancel && <ModalCloseButton />}
        <ModalBody>
          {msg}
          {children}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isButtonLoading}
            colorScheme={'green'}
            variant={'solid'}
            mr={3}
            onClick={onClick}
          >
            {btnText ? btnText : 'Okay'}
          </Button>
          {cancel && (
            <Button colorScheme={'red'} variant={'solid'} onClick={cancel}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  // return (
  //     <div className={ classNames( styles.alertModal, {
  //         [ styles.hide ]: !shown
  //     } ) }>

  //         <div className={ styles.wrapper }>

  //             {
  //                 cancel ?

  //                     <button
  //                         onClick={ cancel }
  //                         className={ styles.cancel }>
  //                         <BsX />
  //                     </button> : null
  //             }

  //             <div className={ styles.modalContent }>

  //                 { icon ? icon : <BsInfoCircle /> }
  //                 <p> { msg ? msg : 'Invalid username and password.' }</p>
  //                 {children}
  //                 {
  //                     !download &&
  //                     <button
  //                     className={ styles.button }
  //                     onClick={ onClick }
  //                     >
  //                     { btnText ? btnText : "Okay" }
  //                     </button>
  //                 }

  //             </div>

  //         </div>
  //     </div>
  // );
};

export default AlertModal;
